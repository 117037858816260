

// Images
import logoCT from "assets/images/GreenBridge/cropped-gbeLogo_icon-180x180.png";


const routes = {
  brand: {
    name: "Green Bridge EV Charging",
    image: logoCT,
    route: "/",
  },
  socials: [
  ],
  menus: [
     {
      name: "help & support",
      items: [
        { name: "Email Us", route: "mailto:support@GreenBridgeEVCharging.com?subject=Help with charging my EV" },
        { name: "FAQ", route: "/faq" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "terms & conditions", route: "/terms" },
        { name: "privacy policy", route: "/privacy" },
      ],
    },
  ],
};

export default routes;