import Card from "@mui/material/Card";
import Header from "components/Header";
import PropTypes from "prop-types";
import Footer from "components/Footer";
import footerRoutes from "footer.routes";

export default function DefaultPage({pageContent}) {
  return (
    <>
      <Header />
      <Card
        sx={{
          py: 2,
          mx: 3,
          mt: -6,
          mb: 4,
          bgColor: "#f0f0f0",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {pageContent}
      </Card>
      <Footer content={footerRoutes} />
    </>
  );
}

DefaultPage.propTypes = {
  pageContent: PropTypes.node,
}