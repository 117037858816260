import MobileStoreButton from 'react-mobile-store-button';
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";


function AppStore() {
    const iOSUrl = 'itms-apps://itunes.apple.com/app/apple-store/id6474174942';
    const androidUrl = 'https://play.google.com/store/apps/details?id=com.greenbridgeevcharging.app';
    return (
        <>
          <MKTypography variant="h3">
            Download the App
          </MKTypography>
          <MKTypography variant="h4">
            Green Bridge EV Charging
          </MKTypography>

          <MKBox mt={2}>
            <MobileStoreButton
              store="ios"
              width="38%"
              url={iOSUrl}
              linkProps={{ title: 'Download the Green Bridge EV Charging app at the iOS App Store' }}
            />
            <MobileStoreButton
              store="android"
              width="42%"
              url={androidUrl}
              linkProps={{ title: 'Get the Green Bridge EV Charging app on Google Play' }}
            />
          </MKBox>
        </>
    );
}
export default AppStore;
