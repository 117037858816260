import {useState} from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import FaqCollapse from "components/FaqCollapse";

// Styling
import "./FAQ.css";


function FAQ() {
  const [collapse, setCollapse] = useState(0);

  return (
    <MKBox component="section" py={4}>
      <Container id={"FAQ"}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={7}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Frequently Asked Questions
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={10}>
            <FaqCollapse
              title="Do I need a smartphone mobile app to charge my car at a Green Bridge EV charger?"
              open={collapse === 10}
              onClick={() => (collapse === 10 ? setCollapse(0) : setCollapse(10))}
            >
              Yes, the Green Bridge EV Charging app. The app is available in both the Apple Store and the Google Play
              Store. Download to set up your profile and payment method. To use the app with a Green Bridge charger, use
              your smartphone’s camera to scan the QR code on the charging station. The Green Bridge app will
              automatically open so you can begin your charging session. (Or, if you don’t already have the Green Bridge
              app on your phone, scanning the QR code with your smartphone camera will prompt you to download the app to
              your phone.)
            </FaqCollapse>
            <FaqCollapse
              title="Do Green Bridge EV chargers support Plug & Charge?"
              open={collapse === 20}
              onClick={() => (collapse === 20 ? setCollapse(0) : setCollapse(20))}
            >
              Not yet, but we plan to soon. Plug & Charge will allow you to plug in without using the app after the
              initial set-up, and the charger will know your car and automatically pay for your session using your
              registered account.
            </FaqCollapse>
            <FaqCollapse
              title="Can I use a credit card reader on Green Bridge EV charging stations?"
              open={collapse === 30}
              onClick={() => (collapse === 30 ? setCollapse(0) : setCollapse(30))}
            >
              No, our chargers do not have credit card readers. Instead, use our convenient mobile app to charge your EV
              at a Green Bridge EV charger. (See above)
            </FaqCollapse>
            <FaqCollapse
              title="Does Green Bridge use solar energy to charge my car at Green Bridge EV stations?"
              open={collapse === 40}
              onClick={() => (collapse === 40 ? setCollapse(0) : setCollapse(40))}
            >
              Green Bridge EV chargers are powered by solar energy at select locations. For example, under a Green
              Bridge solar canopy, when the sun is shining, the electricity generated by the solar flows directly into
              the car. At night and when there is no solar electricity, electricity comes from the utility grid.
            </FaqCollapse>
            <FaqCollapse
              title="Can the Green Bridge EV charging app be used to initiate charging sessions on non-Green Bridge chargers?"
              open={collapse === 50}
              onClick={() => (collapse === 50 ? setCollapse(0) : setCollapse(50))}
            >
              No, but Green Bridge aims to add this functionality in the future.
            </FaqCollapse>
            <FaqCollapse
              title="How fast will my car charge at a Green Bridge station?"
              open={collapse === 60}
              onClick={() => (collapse === 60 ? setCollapse(0) : setCollapse(60))}
            >
              Green Bridge deploys EV chargers at sites where EV drivers are typically parked for several hours. Think
              sites like workplaces, offices, parking garages and commercial & industrial properties. Our charge rate is
              about 25-30 miles/hour. This rate can vary based on several factors including your car’s onboard charger
              capacity, the state of charge of your EV’s battery, the temperature outside, and the status at the
              charging station.
            </FaqCollapse>
            <FaqCollapse
              title="How much will it cost to charge my car?"
              open={collapse === 70}
              onClick={() => (collapse === 70 ? setCollapse(0) : setCollapse(70))}
            >
              The Green Bridge app displays the price for each Green Bridge EV charging station. Prices vary by
              location.
            </FaqCollapse>
            <FaqCollapse
              title="How do I end my EV charging session?"
              open={collapse === 80}
              onClick={() => (collapse === 80 ? setCollapse(0) : setCollapse(80))}
            >
              You can simply unplug your vehicle from the charger to end your session. (Sometimes you need to unlock
              your EV’s doors to release the port from your EV’s charger.) Or, you can press “End Charging Session” in
              the Green Bridge app, or in your car, if available.
            </FaqCollapse>
            <FaqCollapse
              title="Do I need to move my car after a charging session is complete?"
              open={collapse === 1}
              onClick={() => (collapse === 1 ? setCollapse(0) : setCollapse(1))}
            >
              It is courteous to move your EV after charging is complete to make your spot available to others. Some
              Green Bridge stations encourage this action by charging drivers based on time spent at the EV charger.
              Other Green Bridge stations may have an ‘idle fee’ per minute after a 15-minute grace period upon a
              charging session ending.
            </FaqCollapse>
            <FaqCollapse
              title="How do I find Green Bridge charging stations?"
              open={collapse === 100}
              onClick={() => (collapse === 100 ? setCollapse(0) : setCollapse(100))}
            >
              Green Bridge stations are visible on the map screen of the Green Bridge EV Charging App, on PlugShare and
              many mapping systems such as Apple Maps and Google Maps.
            </FaqCollapse>
            <FaqCollapse
              title="How can I report a problem with a Green Bridge charger?"
              open={collapse === 110}
              onClick={() => (collapse === 110 ? setCollapse(0) : setCollapse(110))}
            >
              There are several options. Via the Green Bridge app, you can call us or send an email by pressing the
              information graphic on the lower right side of the app’s nav bar. You can also email us at {" "}
              <a href="mailto:support@GreenBridgeEVCharging.com?subject=Help with charging my EV">support@GreenBridgeEVCharging.com</a>.
            </FaqCollapse>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default FAQ;
