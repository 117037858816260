// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

//
import "./Terms.css";

function Terms() {
  return (
    <>
      <MKBox mx={2}>
        <MKTypography variant="h2">
          Green Bridge Charging Services Terms and Conditions
        </MKTypography>
        <MKTypography mt={4}>
          Green Bridge Electric Vehicle Charging Services are electric vehicle
          charging network services (“Charging Services”) available via the
          Green Bridge EV Charging App (the “App”) and/or electric vehicle
          service equipment (an “EVSE” or “Charging Station”) that has been
          commissioned onto our Cloud Network and/or {" "}
          <a href="https://www.GreenBridgeEVCharging.com" target="_blank" rel="noreferrer noopener">
            www.GreenBridgeEVCharging.com
          </a>{" "}
          (the “Site”).
        </MKTypography>
        <MKTypography mt={4}>
          These charging services terms and conditions (“Green Bridge Charging
          Services Terms”) dated January 2024 constitute a binding legal
          contract and are incorporated as part of the Green Bridge Charging
          Services Terms.
        </MKTypography>
        <MKTypography mt={4}>
          Before downloading the Green Bridge EV Charging App or using any
          Charging Service, you should carefully read the entirety of the Green
          Bridge Charging Service Terms, including without limitation these
          Green Bridge Charging Service Terms. By downloading the Green Bridge
          EV Charging App or otherwise using any charging services, you agree to
          be (a) bound by the Green Bridge Charging Service Terms, including
          without limitation these Green Bridge Charging Service Terms, and (b)
          liable for any noncompliance with the Green Bridge Charging Service
          Terms. If you do not fully agree to these Green Bridge Charging
          Service Terms or the Green Bridge Charging Service Terms in general,
          you must not use the Green Bridge EV Charging App or Charging
          Services. Capitalized terms not defined herein shall have the certain
          meanings ascribed to them elsewhere in the Green Bridge Charging
          Service Terms, including as may be set forth in terms incorporated by
          reference therein (e.g., in the privacy policy).
        </MKTypography>
        <MKTypography mt={4}>
          <div id="terms">
            <ol>
              <li>
                Users
                <br/>
                The following are users of the Charging Services (each, a “User”
                or “you” and, collectively, the “Users”):
              </li>
              <ol>
                <li>
                  A “Driver” is an individual that has downloaded the Green
                  Bridge EV Charging App and registered and maintained a
                  Charging Services account in good standing.
                </li>
              </ol>
              <li>Services; License</li>
              <ol>
                <li>
                  Charging Services may include the processing of payments for
                  charging electric vehicles via an EVSE. You expressly
                  acknowledge and agree that pricing and pricing policies for
                  each EVSE commissioned onto the Cloud Network is determined by
                  Green Bridge in its sole discretion.
                </li>
                <li>
                  Subject to your compliance with these Green Bridge Charging
                  Services Terms, solely for the duration of your use of
                  Charging Services and subject to the restrictions set forth
                  herein, you are hereby granted a revocable, personal, limited,
                  non-sub-licensable, non-exclusive license to access and use the
                  Charging Services. Except as expressly set forth in these
                  Green Bridge Charging Services Terms, you receive no other
                  right, title, or interest in or to the Charging Services or
                  the Green Bridge EV Charging App, Site or EVSEs which may
                  facilitate the Charging Services.
                </li>
              </ol>
              <li>
                Limitations
                <br/>
                You shall not circumvent, nor shall you permit any third party
                to circumvent, any technological measures or features of the
                Site, Green Bridge EV Charging App, EVSEs or Charging Services
                (collectively “Green Bridge”) that are intended to control
                access to or otherwise protect Green Bridge, including without
                limitation any protected content or information contained or
                included in the foregoing. You shall not: (a) use any robot,
                spider, crawler, scraper or other automated means to access
                Green Bridge, (b) take any action that imposes or may impose, in
                Green Bridge’s sole discretion, an unreasonable or
                disproportionately large load on the Green Bridge
                infrastructure, (c) interfere or attempt to interfere with the
                proper operation of Green Bridge, (d) bypass or attempt to
                bypass any security or other measures of Green Bridge that are
                intended to prevent or restrict access to Green Bridge or (e) or
                otherwise use Green Bridge for any purpose not expressly
                permitted herein.
              </li>
              <li>
                Changes to Green Bridge Charging Services Terms
                <br/>
                Green Bridge reserves the right to modify, update, or otherwise
                revise these Green Bridge Charging Services Terms at any time in
                its sole discretion. Such revisions shall be effective
                immediately upon posting, dated revised Green Bridge Charging
                Services Terms on the Site or the Green Bridge EV Charging App.
                Green Bridge Charging Services Terms Green Bridge Charging
                Services Terms. By using Green Bridge after Green Bridge has
                posted any modification, updates, or revisions, you agree to be
                bound by such revised Green Bridge Charging Services Terms.
                Except with regards to the terms and conditions which, by their
                nature, are intended to survive termination (including without
                limitation any perpetual licenses you grant to Green Bridge
                hereunder), if any modification, update, or other change to
                these Green Bridge Charging Services Terms is not acceptable to
                you, you may terminate these Green Bridge Charging Services
                Terms by terminating your Green Bridge account(s) and ceasing
                all uses of the Green Bridge EV Charging App, EVSEs and Charging
                Services.
              </li>
              <li>Intellectual Property Ownership</li>
              <ol>
                <li>
                  “User Content” means any and all: (i) information you submit
                  as part of Green Bridge Charging Services registration, (ii)
                  Submissions, financial history, usage data or log data
                  submitted to or derived in connection with Green Bridge
                  Charging Services. You agree that Green Bridge may retain
                  copies and otherwise use User Content as reasonably necessary
                  for or incidental to its operation of Green Bridge Charging
                  Services or as otherwise described in the Terms (including
                  without limitation these Green Bridge Charging Services Terms)
                  or Green Bridge’s Privacy Policy. In addition to the licenses
                  granted under the Terms, Green Bridge Charging Services Terms
                  you grant to Green Bridge a perpetual, non-exclusive,
                  revocable, worldwide, royalty-free license to use User Content
                  in connection with Green Bridge EV Charging without
                  restriction. Such license shall be deemed granted as of the
                  moment of your Green Bridge account registration without the
                  necessity of any further action on the part of you or Green
                  Bridge.
                </li>
                <li>
                  Subject to your compliance with these Green Bridge Charging
                  Services Terms, solely for the duration of your use of
                  Charging Services and subject to the restrictions set forth
                  herein, you are hereby granted a revocable, personal, limited,
                  non-sublicensable, non-exclusive license to access and use the
                  Charging Services. Except as expressly set forth in these
                  Green Bridge Charging Services Terms, you receive no other
                  right, title, or interest in or to the Charging Services or
                  the Green Bridge EV Charging App, Site or EVSEs which may
                  facilitate the Charging Services.
                </li>
                <li>
                  You acknowledge and agree that you are: (i) simultaneously
                  bound by each Linked Service’s rules or restrictions regarding
                  posting, storing, and using Personal Information on the Linked
                  Services and (ii) solely responsible for any liability that
                  arises from or in relation to your breach of any such rules or
                  restrictions.
                </li>
              </ol>
              <li>
                Links to Other Services
                <br/>
                Green Bridge may be integrated with or link to other third-party
                services (“Linked Services”).
              </li>
              <ol>
                <li>
                  Any Green Bridge integration with Linked Services is provided
                  for convenience and, as such, you access the Linked Services
                  at your own risk and governed by any and all end user license
                  agreements or other terms and conditions issued by the
                  third-party owners or licensors of such Linked Services. Green
                  Bridge does not control Linked Services, and has no
                  responsibility with regards to: (i) your use of any Linked
                  Services or (ii) the content of any Linked Services. A link to
                  Linked Services does not imply or create any Green Bridge
                  endorsement of, sponsorship of, or affiliation with such
                  Linked Services. Without limiting the generality of the
                  foregoing, Green Bridge is not responsible and shall have no
                  liability for any viruses, Trojan Horses or other harmful or
                  illicit code you may come in contact with, in connection with
                  your access or use of Linked Services.
                </li>
                <li>
                  You acknowledge and agree that you are: (i) simultaneously
                  bound by each Linked Service’s rules or restrictions regarding
                  posting, storing, and using Personal Information on the Linked
                  Services and (ii) solely responsible for any liability that
                  arises from or in relation to your breach of any such rules or
                  restrictions.
                </li>
              </ol>
              <li>
                Interruptions to the Services
                <br/>
                You acknowledge and agree that access to Green Bridge may from
                time-to-time be unavailable to you, whether because of technical
                failures or interruptions, intentional downtime for Green Bridge
                or changes to Green Bridge, or otherwise. You agree that (i) any
                modification of Green Bridge, and any interruption or
                unavailability of access to Green Bridge does not constitute a
                default of any Green Bridge obligations under these Green Bridge
                Charging Services Terms or which may otherwise arise in
                connection with your use of Green Bridge and (ii) Green Bridge
                shall have no liability of any nature for any such
                modifications, interruptions, unavailability, or failure of
                access.
              </li>
              <li>
                Technical and Customer Support
                <br/>
                Technical and Customer Support. You may request Charging
                Services technical support via the following methods:
              </li>
              <ol>
                <li>Email: <a
                  href="mailto:support@GreenBridgeEVCharging.com?subject=Help with charging my EV"
                  rel="noreferrer noopener">support@GreenBridgeEVCharging.com</a>
                </li>
                <li>Phone: NEED TO INSERT</li>
                <li>
                  In-App: Call or send an email by pressing the information
                  graphic on the lower right side of the Green Bridge mobile
                  app’s nav bar
                </li>
              </ol>
              <li>
                User Representations and Warranties
                <br/>
                You represent, warrant, and covenant the following:
              </li>
              <ol>
                <li>
                  you will not register with or submit to Green Bridge any User
                  Content that is false or misleading;
                </li>
                <li>
                  by using the Charging Services, the Terms (including without
                  limitation these Green Bridge Charging Services Terms) are
                  valid, binding and enforceable against you;
                </li>
                <li>
                  your performance of your obligations under the Terms will not
                  constitute a breach of any other agreement or obligation by
                  which you are bound;
                </li>
                <li>
                  you are not, and will not be, under any restriction or
                  prohibition related to the performance of your obligations
                  under the Terms;
                </li>
                <li>
                  you will pay all fees and other charges incurred in connection
                  with your or your account’s use of Green Bridge, including
                  without limitation fees incurred by a third party that has
                  access to your account or mobile device;
                </li>
                <li>
                  in the event of any unauthorized use of your Green Bridge
                  account or circumstances that could lead to the unauthorized
                  use of your Green Bridge account, you will contact Green
                  Bridge immediately;
                </li>
                <li>
                  you have and will continue to provide Green Bridge with
                  accurate, current and complete registration and other account
                  information, and will update any and all such information if
                  and as soon as it changes;
                </li>
                <li>
                  you undertake the responsibility for proper use of Green
                  Bridge in accordance with the Terms, including without
                  limitation: (i) understanding and agreeing to the applicable
                  pricing and pricing policies set forth for use of Charging
                  Services, (ii) understanding, agreeing to and complying with
                  any additional or terms or conditions applicable to Linked
                  Services, (iii) ensuring your applicable Charging Service
                  payment method is active and permitted to be used with your
                  account and (iv) correctly securing the charge port connector
                  to your electric vehicle and verifying that charging properly
                  commences;
                </li>
                <li>
                  you are at least eighteen (18) years of age or the legal age
                  to operate a motor vehicle in the applicable jurisdiction,
                  with the knowledge and consent of your parent or legal
                  guardian;
                </li>
                <li>
                  you are duly licensed by applicable government authorities to
                  operate the motor vehicle you seek to use in connection with
                  Charging Services;
                </li>
                <li>
                  you will maintain any information you use to access Green
                  Bridge, including without limitation any User IDs or passwords
                  in strict confidence and not share such information with third
                  parties;
                </li>
                <li>
                  you will refrain from sharing confidential login information
                  with any third parties, except as otherwise required by law;
                </li>
                <li>
                  you will change your Green Bridge account password immediately
                  upon becoming aware that it has been compromised;
                </li>
                <li>
                  Green Bridge, as well as its affiliates and licensors, will
                  not be liable for any loss, damage, claim or liability that
                  may arise from your breach of or failure to comply with this
                  Section 9.
                </li>
              </ol>
              <li>No Warranty; Disclaimer</li>
              <ol>
                <li>
                  You acknowledge and agree that you use Green Bridge charging
                  services at your sole risk. Green Bridge and the licenses
                  granted to you under these Green Bridge Charging Services
                  Terms are provided on an “as is” and “as available” basis,
                  without any warranty of any kind or nature, including without
                  limitation warranties of title, merchantability, fitness for a
                  particular purpose non-infringement, course of dealing or
                  course of performance.
                </li>
                <li>
                  Green Bridge and its licensors expressly disclaim any and all
                  warranties regarding the Charging Services or related EVSE
                  hardware or software, whether express, implied or statutory,
                  including without limitation any implied warranties of title,
                  merchantability, fitness for a particular purpose,
                  non-infringement, course of dealing or course of performance.
                </li>
                <li>
                  You acknowledge and agree that vehicle charging in connection
                  with your use of Charging Services is done at your own
                  discretion and risk, and that you are and will be solely
                  responsible for any damage to your vehicle or any computer
                  system that results from your use of the service to charge a
                  vehicle. You further acknowledge and agree that (i) EVSE s
                  include high voltage and thus potentially dangerous equipment
                  and (ii) unless otherwise prohibited by applicable laws or
                  regulations, you waive any claims for damages, losses or
                  otherwise that you may have against Green Bridge, its
                  franchisees, licensors, affiliates and EVSE hardware providers
                  in relation to property damage or personal injuries incurred
                  in connection with your use of Charging Services.
                </li>
              </ol>
              <li>Limitation of Liability</li>
              <ol>
                <li>
                  The entire and cumulative liability of Green Bridge, its
                  affiliates, franchisees, licensors and EVSE hardware providers
                  (to you or any third party) for any losses or damages arising
                  out of or otherwise relating to these Green Bridge Charging
                  Services Terms shall not exceed an amount equal to the greater
                  of (i) the total amount you spent via the Green Bridge EV
                  charging app in the twelve (12) month period preceding the
                  events giving rise to the claim or (ii) USD $100.00.
                </li>
                <li>
                  Without limiting and notwithstanding the foregoing, in no
                  event shall Green Bridge, its affiliates, franchisees,
                  licensors or EVSE hardware providers be liable to you or any
                  third party for any loss of business or anticipatory profits
                  or any indirect, punitive, special, incidental, general,
                  exemplary or consequential damages (including without
                  limitation lost profits or loss of business), even if
                  foreseeable or if you, Green Bridge or any Green Bridge
                  affiliate(s), franchisee(s), licensor(s) or EVSE hardware
                  provider(s) have or has been advised of the possibility of
                  such loss, damage or expense.
                </li>
                <li>
                  Without limiting the foregoing, you acknowledge and agree that
                  Green Bridge does not and cannot completely control the flow
                  or security of data to or from Green Bridge and other portions
                  of the internet. Such flow depends in large part on (i) the
                  performance of internet services provided or controlled by
                  third parties and (ii) the security measures you utilize an
                  maintain in connection with your use of online services. At
                  times, actions or inactions of you or third parties may impair
                  or disrupt your or other third parties’ secure connections to
                  and use of the internet and Green Bridge. Although Green
                  Bridge will use commercially reasonable efforts to take
                  actions within its control that it deems appropriate to
                  maintain secure connections to Green Bridge, Green Bridge
                  cannot guarantee that connection disruptions or adverse
                  security events will not occur. Accordingly, Green Bridge
                  disclaims any and all liability resulting from or related to
                  such events.
                </li>
                <li>
                  You acknowledge that the limitations set forth in this section
                  11 (limitation of liability) are an essential basis of the
                  bargain and of the allocation of risks between you and Green
                  Bridge. Some states or jurisdictions do not allow the
                  limitation or exclusion of liability for incidental or
                  consequential damages. Accordingly, some of the above
                  limitations may not apply to you. If you are dissatisfied with
                  any portion or part of the Charging Services, or with any of
                  these Green Bridge Charging Services Terms, your sole and
                  exclusive remedy is to discontinue use of the Green Bridge EV
                  Charging App and Charging Services.
                </li>
              </ol>
              <li>
                Equitable Relief
                <br/>
                You agree that a breach or violation of Sections 2, 3, 5, or 9
                of these Green Bridge Charging Services Terms will result in
                immediate and irreparable injury and harm to Green Bridge. In
                such event, Green Bridge shall have, in addition to any and all
                other remedies it may have available to it at law or under the
                Terms (including without limitation monetary damages under these
                Green Bridge Charging Services Terms), the right to an
                injunction, specific performance or other equitable relief to
                prevent or otherwise remediate the given breach or violation.
              </li>
              <li>
                Indemnification
                <br/>
                You shall indemnify, defend and hold harmless Green Bridge, its
                affiliates, franchisees, licensors, EVSE hardware providers
                (collectively, “Indemnities”), as well as each Indemnities’
                officers, directors, employees, agents, shareholders, members
                and managers from and against any and all claims, losses,
                demands, liabilities and actions, including without limitation
                the payment of all attorneys’ fees and other legal expenses,
                that arise in connection with: (a) these Green Bridge Charging
                Services Terms, (b) any User Content alleged to infringe,
                misappropriate or otherwise violate any intellectual property or
                other proprietary right of any person or entity anywhere in the
                world or (z) personal injury or property damage related to your
                use of Charging Services. Notwithstanding the foregoing, Green
                Bridge may control its own defense and engage its own legal
                counsel in its sole discretion.
              </li>
              <li>Fees and Payment</li>
              <ol>
                <li>
                  You shall pay Green Bridge all applicable fees and related
                  taxes that arise in connection with your use of Green Bridge
                  Charging Services.
                </li>
                <li>
                  You authorize Green Bridge to temporarily charge a
                  pre-authorization amount of one dollar ($1.00) to each payment
                  method you add to your Green Bridge account so as to verify
                  validity of such payment method.
                </li>
                <li>
                  You authorize Green Bridge to charge all applicable Charging
                  Services fees to the payment card or other payment account(s)
                  included in your Green Bridge account. You further acknowledge
                  and agree that a third-party payment processing company may
                  store such payment card or other payment account information.
                  You will be automatically charged for all fees and taxes
                  incurred in relation to the Charging Services as such fees and
                  taxes become due. Your obligations to pay fees continue until
                  Green Bridge has received all payments in full. If your
                  payment card issuer, account or bank does not honor charges
                  you have incurred, or otherwise incurred under your Green
                  Bridge account, you will remain directly liable to Green
                  Bridge for all unpaid amounts.
                </li>
                <li>
                  Non-payment of any fees, taxes or other sums due to Green
                  Bridge in relation to the Charging Services may result in
                  termination of your Green Bridge account, as determined by
                  Green Bridge in its sole discretion. Green Bridge may, in its
                  sole discretion, also appoint an outside debt collection
                  agency to collect amounts owed to Green Bridge in relation to
                  Charging Services used by you or another individual using your
                  account. You agree to reimburse Green Bridge for all costs
                  Green Bridge incurs in collecting such unpaid amounts,
                  including without limitation debt collection agency fees and
                  reasonable attorneys’ and other legal expenses.
                </li>
                <li>
                  You must notify Green Bridge of any and all overcharges or
                  other billing disputes within thirty (30) days of the date the
                  disputed charge was added to your account. If you dispute a
                  charge to your payment card issuer, account or bank that, in
                  Green Bridge’s reasonable judgment, is a valid charge under
                  the provisions of the Terms (including without limitation
                  these Green Bridge Charging Services Terms), you agree to
                  reimburse Green Bridge for its reasonable costs incurred in
                  connection with the investigation and resolution of the
                  matter. Green Bridge will refund any such fees if the
                  investigation finds that your action in disputing the charge
                  to your card issuer, account or bank was justified because the
                  charge was not, in fact, a valid charge under the provisions
                  of the Terms.
                </li>
                <li>
                  All fees and charges are non-refundable and there are no
                  refunds.
                </li>
              </ol>
              <li>
                Collection and Use of Personal Information
                <br/>
                The California Department of General Services (DGS), Office of
                Fleet and Asset Management (OFAM) offers electric vehicle
                charging stations to reduce greenhouse gas emissions and
                mitigate the impacts of climate change as directed by Executive
                Order N-19-19. DGS collects personal information such as name,
                email address, and mobile phone number. Any personal information
                collected by state agencies is subject to the limitations in the
                Information Practices Act and state policy. The DGS’ general
                privacy policy is available at {" "}
                <a href="https://www.dgs.ca.gov/Privacy" target="_blank" rel="noreferrer noopener">
                  https://www.dgs.ca.gov/Privacy
                </a>
                .
              </li>
            </ol>
          </div>
        </MKTypography>
      </MKBox>
    </>
  );
}

export default Terms;
