// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React examples
import LiftedImageCard from "components/LiftedImageCard";

// Other components
import AppStore from "components/AppStore";
import SiteImage from "assets/images/GreenBridge/1300VirginiaDrone2.png"
import MKTypography from "components/MKTypography";
import ChargerImage from "assets/images/GreenBridge/GB_EVSE_charger w GB full logo (clear).png";

function Home() {
  return (
    <Container>
      <Grid container spacing={4} align="center">
        <Grid item xs={12} md={6} xl={4} mt={{xs: -5, sm: -10, md: -14}}>
          <img src={ChargerImage} width={250} alt="Green Bridge Charger"/>
        </Grid>
        <Grid item xs={12} md={6} xl={4} sx={{marginTop: {xs: -20, sm: -16, md: 2}}}>
          <Card sx={{paddingTop: 2, background: "#F8D2C4", maxWidth: 400}}>
            <AppStore/>
          </Card>
          <MKTypography variant="h3" mt={8}>
            <a href="/faq">Frequently Asked Questions</a>
          </MKTypography>
        </Grid>
        <Grid item xs={12} md={12} lg={6} xl={4}>
          <LiftedImageCard
            image={SiteImage}
            title="Charge your car using the sun"
            description="Green Bridge offers solar EV Charging solutions for commercial real estate clients. Our solar canopies provide clean energy to power EVs at commercial locations."    
         />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Home;
