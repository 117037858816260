// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Styling
import "./Privacy.css";

function Privacy() {
  return (
    <>
      <MKBox id="privacy" mx={2}>
        <MKTypography variant="h2">
          Green Bridge Charging Services Privacy Policy
        </MKTypography>
        <MKTypography>
          Protecting your private information is a priority for us. This Statement of Privacy applies to Green Bridge
          Electric Vehicle (EV) Charging Services and governs data collection and usage. For the purposes of this
          Privacy Policy, unless otherwise noted, all references to “Green Bridge” include
          https://www.GreenBridgeEVCharging.com (the “Site”) the Green Bridge EV Charging App (the “App”)
          and EV charging services (the “Services”). By using the Green Bridge Site or App, you consent to the
          data practices described in this statement.
        </MKTypography>
        <MKTypography variant="h3">
          Collection of your Personal Information
        </MKTypography>
        <MKTypography>
          In order to better provide you with products and services offered on our App, Green Bridge may collect
          personally identifiable information, such as your Email Address and name.
        </MKTypography>
        <MKTypography>
          We do not collect any personal information about you unless you voluntarily provide it to us. However, you
          may be required to provide certain personal information to us when you elect to use certain Services
          available on the App. These may include: (a) registering for an account on our App; (b) signing up for
          special offers from selected third parties; (c) sending us an email message; (d) submitting your credit card
          or other payment information when ordering and purchasing Services. To wit, we will use your information
          for, but not limited to, communicating with you in relation to Services you have requested from us. We also
          may gather additional personal or non-personal information in the future.
        </MKTypography>
        <MKTypography variant="h3">
          Use of your Personal Information
        </MKTypography>
        <MKTypography>
          Green Bridge collects and uses your personal information to operate its App and deliver the Services you
          have requested including communications related to customer support. Green Bridge may also use your
          personally identifiable information to inform you of other products or services available from Green Bridge
          and its affiliates.
        </MKTypography>
        <MKTypography variant="h3">
          Sharing Information with Third Parties
        </MKTypography>
        <MKTypography>
          Green Bridge does not sell, rent or lease its customer lists to third parties. Green Bridge may share data
          with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer
          support, or arrange for deliveries. All such third parties are prohibited from using your personal
          information except to provide these services to Green Bridge, and they are required to maintain the
          confidentiality of your information. Green Bridge may disclose your personal information, without notice, if
          required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the
          edicts of the law or comply with legal process served on Green Bridge or the App; (b) protect and defend the
          rights or property of Green Bridge; and/or (c) act under exigent circumstances to protect the personal
          safety of users of Green Bridge, or the public.
        </MKTypography>
        <MKTypography variant="h3">
          Tracking User Behavior
        </MKTypography>
        <MKTypography>
          Green Bridge may keep track of the Site pages our users visit within Green Bridge, in order to determine
          what Green Bridge Services are the most popular. This data is used to deliver customized content and
          advertising within Green Bridge to customers whose behavior indicates that they are interested in a
          particular subject area. Green Bridge uses any and all methods utilized by Google analytics.
        </MKTypography>
        <MKTypography variant="h3">
          Automatically Collected Information
        </MKTypography>
        <MKTypography>
          Information about your computer hardware and software may be automatically collected by Green Bridge. This
          information can include: your IP address, browser type, domain names, access times and referring Site
          addresses. This information is used for the operation of the Services, to maintain quality of the Services,
          and to provide general statistics regarding use of the Green Bridge Site.
        </MKTypography>
        <MKTypography variant="h3">
          Security of your Personal Information
        </MKTypography>
        <MKTypography>
          Green Bridge secures your personal information from unauthorized access, use, or disclosure. Green Bridge
          uses the following methods for this purpose: SSL Protocol. When personal information (such as a credit card
          number) is transmitted to other Sites, it is protected through the use of encryption, such as the Secure
          Sockets Layer (SSL) protocol. We strive to take appropriate security measures to protect against
          unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over
          the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to
          protect your personal information, you acknowledge that: (a) there are security and privacy limitations
          inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and
          all information and data exchanged between you and us through this Site cannot be guaranteed.
        </MKTypography>
        <MKTypography variant="h3">
          Right to Deletion
        </MKTypography>
        <MKTypography>
          Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will, a) Delete
          your personal information from our records; and b) Direct any service providers to delete your personal
          information from their records. Please note that we may not be able to comply with requests to delete your
          personal information if it is necessary to, a) Complete the transaction for which the personal information was
          collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law,
          provide a good or service requested by you, or reasonably anticipated within the context of our ongoing
          business relationship with you, or otherwise perform a contract between you and us, b) Detect security
          incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those
          responsible for that activity, c) Debug to identify and repair errors that impair existing intended
          functionality, d) Exercise free speech, ensure the right of another consumer to exercise his or her right of
          free speech, or exercise another right provided for by law, e) Comply with the California Electronic
          Communications Privacy Act, f) Engage in public or peer-reviewed scientific, historical, or statistical
          research in the public interest that adheres to all other application ethics and privacy laws, when our
          deletion of the information is likely to render impossible or seriously impair the achievement of such
          research, provided we have obtained your informed consent, g) Enable solely internal uses that are reasonably
          aligned with your expectations based on your relationship with us, h) Comply with an existing legal
          obligation; or i) Otherwise use your personal information, internally, in a lawful manner that is compatible
          with the context in which you provided the information.
        </MKTypography>
        <MKTypography variant="h3">
          Children Under Thirteen
        </MKTypography>
        <MKTypography>
          Green Bridge does not knowingly collect personally identifiable information from children under the age of
          thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use
          our Site or App.
        </MKTypography>
        <MKTypography variant="h3">
          E-mail Communications
        </MKTypography>
        <MKTypography>
          From time to time, Green Bridge may contact you via email for the purpose of providing announcements,
          promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve
          our Services, we may receive a notification when you open an email from Green Bridge or click on a link
          therein. If you would like to stop receiving marketing or promotional communications via email from Green
          Bridge, you may opt out of such communications by clicking on the “Unsubscribe” button in emails.
        </MKTypography>
        <MKTypography variant="h3">
          External Data Storage Sites
        </MKTypography>
        <MKTypography>
          We may store your data on servers provided by third party hosting vendors with whom we have contracted.
        </MKTypography>
        <MKTypography variant="h3">
          Changes to this Statement
        </MKTypography>
        <MKTypography>
          Green Bridge reserves the right to change this Privacy Policy from time to time. We will notify you about
          significant changes in the way we treat personal information by placing a notice on our site, and/or by
          updating any privacy information on this page. Your continued use of the Site and/or App and/or Services
          after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b)
          agreement to abide and be bound by that Policy.
        </MKTypography>
        <MKTypography variant="h3">
          Contact Information
        </MKTypography>
        <MKTypography>
          Green Bridge welcomes your questions or comments regarding this Statement of Privacy. If you believe that
          Green Bridge has not adhered to this Statement, please contact Green Bridge at:
        </MKTypography>
        <MKTypography>
          <ol>
            <li>Email: <a
              href="mailto:support@GreenBridgeEVCharging.com?subject=Help with charging my EV"
              rel="noreferrer noopener">support@GreenBridgeEVCharging.com</a>
            </li>
            <li>Phone: 215-866-4734</li>
            <li>
              In-App: Call or send an email by pressing the information
              graphic on the lower right side of the Green Bridge mobile
              app’s nav bar
            </li>
          </ol>
        </MKTypography>
        <MKTypography>
          Effective as of <strong>January 15, 2024</strong>
        </MKTypography>
      </MKBox>
    </>
  );
}

export default Privacy;
