// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import Logo from "assets/images/GreenBridge/cropped-gbeLogo_icon-180x180.png";
import bgBolt from "assets/images/GreenBridge/1300V_Bolt_IoTecha.png";
import bgTesla from "assets/images/GreenBridge/1300V_Tesla_IoTechaGreenBridge.png";

function bgImage() {
  let images = [bgBolt, bgTesla];
  return images[Math.floor(images.length * Math.random())];
}

const Header = () => {
  return (
    <MKBox
      minHeight={{xs: "300px", sm: "25vh"}}
      width="100%"
      sx={{
        backgroundImage: ({
                            functions: {linearGradient, rgba},
                            palette: {gradients},
                          }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.3),
            rgba(gradients.dark.state, 0.9)
          )}, url(${bgImage()})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
      }}
    >
      <Grid container>
        <Grid item xs={4} pt={1} pl={{xs: 1, sm: 4, md: 6, lg: 8, xl: 14}} >
          <a href="/">
            <img src={Logo} height="50%" alt="Green Bridge Logo"/>
          </a>
        </Grid>
        <Grid item xs={8} sx={{margin: "auto"}}>
          <MKTypography
            variant="h2"
            color="white"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
            })}
          >
            Green Bridge EV Charging
          </MKTypography>
          <MKTypography variant="body1" color="white" opacity={0.8} pt={1} pb={3}>
            Easy and convenient electric vehicle charging
          </MKTypography>
        </Grid>
      </Grid>
    </MKBox>
  );
};

export default Header;